
































import { Component, Prop, Watch } from 'vue-property-decorator';
import {
  Getter, Mutation, namespace, State,
} from 'vuex-class';
import UiPage from '@/models/graphql/UiPage';
import UiPageWidget from '@/models/graphql/UiPageWidget';
import { BasicTypes } from '@/utils/types/BasicTypes';
import LoadWidgetComponent from '@/components/LoadWidgetComponent.vue';
import Community from '@/models/graphql/Community';
import CommunityUser from '@/models/graphql/CommunityUser';
import components from '@/utils/widgets/WidgetComponents';
import WidgetHelper from '@/utils/helpers/widgets/WidgetHelper';
import GenericPageGuard from '@/guards/GenericPageGuard';
import Session from '@/models/graphql/Session';
import EntityType from '@/utils/enums/EntityType';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import NotFound from '@/views/error/NotFound.vue';
import Forbidden from '@/views/error/Forbidden.vue';
import LiveSessionAlertComponent from '@/components/video-player/LiveSessionAlertComponent.vue';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import { isBefore } from 'date-fns';
import NotificationEventType from '@/utils/enums/notification/NotificationEventType';
import SubscriptionEvent from '@/utils/types/SubscriptionEvent';
import Notification from '@/models/graphql/Notification';
import CompanyUserRole from '@/models/graphql/CompanyUserRole';
import CompanyUserRoleStatusType from '@/utils/enums/CompanyUserRoleStatusType';
import VueBaseNotify from '@/utils/widgets/VueBaseNotify';
import UiOnboarding from '@/models/graphql/UiOnboarding';
import { runMathJax } from '@/utils/helpers/LatexHelper';
import { PortalTarget } from 'portal-vue';
import MenuType from '@/utils/enums/MenuType';
import MenuModel from '@/models/MenuModel';
import OrderPackage from '@/models/graphql/OrderPackage';
import PackageUpgrade from '@/views/error/PackageUpgrade.vue';
import SalesPackage from '@/models/graphql/SalesPackage';
import Exhibitor from '@/models/graphql/Exhibitor';
import CompanyUserRolePermission from '@/utils/enums/CompanyUserRolePermission';

const widgetDispatcherStore = namespace('WidgetDispatcherStore');
const pageStateManagementStore = namespace('PageStateManagementStore');
const permissionManagerStore = namespace('PermissionManagerStore');
const orderPackageStore = namespace('OrderPackageStore');
const salesPackageStore = namespace('SalesPackageStore');
const exhibitorStore = namespace('ExhibitorStore');

/* eslint-disable no-underscore-dangle */

@Component({
  components: {
    PackageUpgrade,
    LiveSessionAlertComponent,
    Forbidden,
    NotFound,
    LoadWidgetComponent,
    PortalTarget,
  },
  name: 'GenericPage',
  beforeRouteLeave: GenericPageGuard.beforeLeave,
  beforeRouteEnter: GenericPageGuard.beforeEnter,
})
export default class GenericPage extends VueBaseNotify {
  @Getter
  community!: Community;

  @Getter
  authUser!: CommunityUser;

  @Getter
  communityTimeZone!: string;

  @Getter
  featureByKey!: (key: FeatureKeys) => CommunityFeature;

  @State('pages')
  rootPages!: UiPage[];

  @State
  onBoardings!: UiOnboarding[];

  @Mutation
  setCurrentPage!: (page: UiPage | null) => void;

  @Mutation
  setPageClasses!: () => void;

  @widgetDispatcherStore.Action
  loadPageData!: (pageId: number) => Promise<Record<string, BasicTypes[]>>;

  @widgetDispatcherStore.Action
  loadPageDependencies!: (page: {
    uid: string;
    parentUid?: string;
  }) => Promise<void>;

  @widgetDispatcherStore.Mutation
  setMagicArgs!: (magicArgs: Record<string, BasicTypes>) => void;

  @pageStateManagementStore.State
  pageReadyToLoadData!: number[];

  @pageStateManagementStore.State
  dependenciesCreated!: Record<number, string[]>;

  @pageStateManagementStore.Mutation
  addDependency!: (payload: { page: number; widget: string }) => void;

  @pageStateManagementStore.Mutation
  addLoadingState!: (payload: { page: number; widget: string }) => void;

  @permissionManagerStore.Getter
  canModerate!: boolean;

  @orderPackageStore.Action('getForPageRestriction')
  getOrderPackageForPage!: (payload: { filter: object }) => Promise<OrderPackage[] | undefined>;

  @salesPackageStore.Action('getPackages')
  private getPackages!: (payload: { filter: object; exhibitorId: string | number })
    => Promise<SalesPackage[] | undefined>;

  @exhibitorStore.Action
  private loadAdminPanelExhibitor!: ({
    uid,
    authUser,
  }: { uid: string; authUser: string })
    => void;

  @exhibitorStore.Getter('fetchAdminPanelExhibitor')
  private adminPanelExhibitor!: Exhibitor;

  @Getter
  private readonly menuByType!: (menuType: MenuType) => MenuModel[];

  @Getter
  private readonly isUnifyExhibitorPortal!: boolean;

  @State
  private selectedTzName!: string;

  @Prop({ required: true })
  private page!: UiPage;

  private magicArgs: Record<string, string | number> = {};

  private notFoundPage = false;

  private notAccessible = false;

  private needPackageUpgrade = false;

  private availableSalesPackages: SalesPackage[] = [];

  private packageLoading = true;

  private noPackageForSalesComponent = false;

  private currentActiveMenu: MenuModel | null = null;

  private session: Session | null = null;

  private cssClasses = '';

  private salesComponentId: number | null = null;

  private widgets: UiPageWidget[] = [];

  get showLiveSessionAlert(): boolean {
    if (this.session && (this.session.startTime || this.session.videoEnabledStartTime)) {
      const today = DateTimeHelper.getCurrentDateTime();
      let startTime = today;
      if (this.session.startTime) {
        startTime = DateTimeHelper.utcToZonedTimeDate(
          `${this.session.startTime}Z`,
          this.selectedTzName,
        );
      }
      if (this.session.videoEnabledStartTime) {
        startTime = DateTimeHelper.utcToZonedTimeDate(
          `${this.session.videoEnabledStartTime}Z`,
          this.selectedTzName,
        );
      }
      return !!(this.session
        && this.session.videoEnabled === true
        && this.session.videoLiveEnabled === true
        && this.session.videoType
        && this.session.videoType === 'live'
        && isBefore(today, startTime));
    }
    return false;
  }

  get liveSessionWidget(): UiPageWidget | undefined {
    if (this.session) {
      return {
        uid: this.session.uid,
        id: this.session.id,
        displaySequence: 1,
        dataKey: [],
        subWidgets: [],
      };
    }
    return undefined;
  }

  get isOverflowVisible(): boolean {
    return !(this.$route.meta && this.$route.meta.footer && this.$route.meta.header);
  }

  private get mainMenuList(): MenuModel[] {
    return this.menuByType(MenuType.MAIN);
  }

  updateMagicArgs(): void {
    this.magicArgs = {
      community: this.community.code || '',
      authUser: this.authUser ? this.authUser.uid : '',
      locale: this.$store.state.i18n?.locale,
      now: DateTimeHelper.currentTimestamp,
      currentTimeZone: this.selectedTzName,
      communityTimeZone: this.communityTimeZone,
      search: '',
      page: '1',
    };
    if (this.$route.query && Object.keys(this.$route.query).length > 0) {
      Object.assign(this.magicArgs, { ...this.$route.query });
    }
    if (Object.keys(this.$route.params).length > 0) {
      Object.assign(this.magicArgs, { ...this.$route.params });
    }
    this.setMagicArgs(this.magicArgs);
  }

  created(): void {
    this.notifyEvents = [NotificationEventType.COMPANY_INVITE];
    if (this.$route.name === 'member-detail'
      && this.authUser
      && this.$route.params.memberId === this.authUser.uid) {
      this.$router.replace({ name: 'my-profile' });
    }
    if (this.$route.meta
      && this.$route.meta.entityType
      && this.$route.params.sessionId
      && this.$route.meta.entityType === EntityType.SESSION_CMS) {
      this.notAccessible = !this.canModerate;
    }
    if (this.$route.meta
      && this.$route.meta.entityType
      && this.$route.meta.entityType === EntityType.COMPANY_CMS) {
      this.setCurrentMenuAndSaleComponentId();
      if (this.authUser
        && this.authUser.companyRoles
        && this.authUser.companyRoles.length > 0) {
        const entityType = this.$route.meta?.entityType;
        const found = this.authUser.companyRoles.find((companyRole) => entityType
          && companyRole.company
          && companyRole.company.uid === this.$route.params.companyId);
        if (!found) {
          this.notAccessible = true;
        } else if (this.currentActiveMenu && this.currentActiveMenu.userPermissionNeeded && found) {
          switch (this.currentActiveMenu.userPermissionNeeded) {
            case CompanyUserRolePermission.MODERATOR:
              this.notAccessible = !found.moderator;
              break;
            case CompanyUserRolePermission.CAN_POST:
              this.notAccessible = !found.canPost;
              break;
            case CompanyUserRolePermission.MANAGE_SALES:
              this.notAccessible = !found.manageSales;
              break;
            case CompanyUserRolePermission.HANDLE_MEETINGS:
              this.notAccessible = !found.handleMeetings;
              break;
            case CompanyUserRolePermission.MANAGE_CONTENT:
              this.notAccessible = !found.manageContent;
              break;
            case CompanyUserRolePermission.MANAGE_COMPANY_USER_ROLES:
              this.notAccessible = !found.manageCompanyUserRoles;
              break;
            default:
              break;
          }
        }
      } else {
        this.notAccessible = true;
      }
    }
    this.updateMagicArgs();
    if (this.isUnifyExhibitorPortal) {
      this.needPackageUpgrade = true;
      this.loadPackageComponentForPage();
    }
    this.setCurrentPage(this.page);
    if (!this.page.parentPage) {
      this.setPageClasses();
    } else {
      this.cssClasses = this.page.cssClasses || '';
    }
    let uiOnboardingWidgets: UiPageWidget[] = [];
    if (this.onBoardings.length > 0) {
      uiOnboardingWidgets = this.onBoardings
        .filter((onboarding) => onboarding.page === undefined || onboarding.page?.uid === this.page.uid)
        .map((item, index) => UiPageWidget.hydrate({
          uid: item.uid,
          id: item.id,
          page: this.page,
          schemaCode: item.schemaCode,
          displaySequence: this.widgets.length + index + 1,
          dataKey: [],
          type: 'destination_ui_onboarding',
          payload: JSON.stringify({
            code: 'UiOnBoardingWidget',
            type: item.code,
            autoshow: item.autoshow,
            guest: item.guest,
            enabled: item.enabled,
            version: item.version,
          }),
          subWidgets: UiPage.mappingWidgets(item.pageWidgets
            .map((w) => UiPageWidget.hydrate({
              ...w,
              page: { id: this.page.id },
            }))),
        }));
    }
    this.loadPageDependencies({
      uid: this.page.uid,
      parentUid: this.page.parentPage?.uid,
    })
      .then(() => {
        if (this.page.widgets) {
          this.widgets = [...this.page.widgets, ...uiOnboardingWidgets];
        }
      })
      .then(() => {
        this.subscribeToWidgetStateStore(this.widgets);
      });
  }

  public mounted(): void {
    if (this.isUnifyExhibitorPortal && this.magicArgs.companyId && (!this.adminPanelExhibitor || !this.adminPanelExhibitor.uid)) {
      this.loadAdminPanelExhibitor({
        uid: this.magicArgs.companyId as string,
        authUser: this.authUser.uid,
      });
    }
  }

  protected notificationCallback(event: SubscriptionEvent): void {
    if (this.$route.meta
      && this.$route.meta.entityType
      && this.$route.meta.entityType === EntityType.COMPANY_CMS
      && event.type === NotificationEventType.COMPANY_INVITE) {
      const notification = Notification.hydrate(event.data);
      const companyUserRole = notification.triggered as CompanyUserRole;
      if (notification
        && companyUserRole
        && companyUserRole.state
        && companyUserRole.state === CompanyUserRoleStatusType.CANCELLED
        && companyUserRole.user
        && companyUserRole.user.uid === this.authUser.uid) {
        const entityType = this.$route.meta?.entityType;
        const found = this.authUser.companyRoles?.find((companyRole: CompanyUserRole) => entityType
          && companyRole.company
          && companyUserRole.company
          && companyRole.company.uid === companyUserRole.company.uid);
        if (!found) {
          this.notAccessible = true;
        }
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private updated(): void {
    runMathJax();
  }

  @Watch('pageReadyToLoadData', { deep: true })
  private loadDependencies(): void {
    if (this.page
      && this.page.id
      && !this.notAccessible
      && !this.notFoundPage
      && this.pageReadyToLoadData.includes(this.page.id)) {
      this.loadPageData(this.page.id);
    }
  }

  private subscribeToWidgetStateStore(widgets: UiPageWidget[]): void {
    widgets.forEach((w) => {
      const payload = JSON.parse(w.payload || '{}');
      if (w.page
        && w.page.id
        && payload
        && payload.code
        && Object.keys(components)
          .includes(payload.code)) {
        this.addDependency({
          page: w.page.id,
          widget: WidgetHelper.storeName(w.type, `${w.id}${w.page?.id}`),
        });
        this.addLoadingState({
          page: w.page.id,
          widget: WidgetHelper.storeName(w.type, `${w.id}${w.page?.id}`),
        });
      }
      if (w.subWidgets && w.subWidgets.length > 0) {
        this.subscribeToWidgetStateStore(w.subWidgets);
      }
    });
  }

  private setCurrentMenuAndSaleComponentId(): void {
    const currentActiveMenu = this.mainMenuList.find((menu) => menu.subMenu && menu.subMenu.length > 0
        && menu.subMenu.findIndex((subMenu) => this.$route.meta && subMenu.targetPage === this.$route.meta.pageId) > -1)
      || this.mainMenuList
        .find((menu) => this.$route.meta && menu.targetPage === this.$route.meta.pageId);
    let currentActiveSubMenu = null;
    if (currentActiveMenu && currentActiveMenu.subMenu) {
      currentActiveSubMenu = currentActiveMenu.subMenu.length > 0
        ? currentActiveMenu?.subMenu.find((subMenu) => this.$route.meta && subMenu.targetPage === this.$route.meta.pageId)
        : null;
    }
    if (currentActiveSubMenu) {
      this.salesComponentId = currentActiveSubMenu.salesComponent ?? null;
      this.currentActiveMenu = currentActiveSubMenu;
    } else if (currentActiveMenu) {
      this.salesComponentId = currentActiveMenu.salesComponent ?? null;
      this.currentActiveMenu = currentActiveMenu;
    }
  }

  @Watch('adminPanelExhibitor', { deep: true })
  private loadPackageComponentForPage(): void {
    this.needPackageUpgrade = true;
    this.packageLoading = true;
    if (this.isUnifyExhibitorPortal && this.adminPanelExhibitor && this.adminPanelExhibitor.uid) {
      if (this.salesComponentId) {
        const queryFilter = {
          active: true,
          salesPackageComponent: {
            salesComponent: {
              id: this.salesComponentId,
              schemaCode: this.community.code,
            },
          },
        };

        this.getPackages({
          filter: queryFilter,
          exhibitorId: this.magicArgs.companyId,
        })
          .then((response: SalesPackage[] | undefined) => {
            if (response && response.length > 0) {
              this.noPackageForSalesComponent = false;
              const defaultPackage = response.find((availablePackage: SalesPackage) => availablePackage.includedByDefault);
              if (defaultPackage) {
                this.needPackageUpgrade = false;
              } else {
                this.needPackageUpgrade = !response.find((availablePackage: SalesPackage) => availablePackage._isPurchased > 0);
                this.availableSalesPackages = response || [];
              }
              this.packageLoading = false;
            } else {
              this.noPackageForSalesComponent = true;
            }
          });
      } else {
        this.needPackageUpgrade = false;
        this.packageLoading = false;
      }
    } else {
      this.needPackageUpgrade = false;
      this.packageLoading = false;
    }
  }
}
